




































































































































































































































































































































































import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class NoConnectionComponent extends Vue {
    @Prop({type: String, required: false, default: ''})
    public title!: string | undefined | null;
    @Prop({type: String, required: false, default: ''})
    public subtitle!: string | undefined | null;
}
