import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import {cloneDeep} from 'lodash';
import store from '@/store';
import {RegisteredResultViewModel} from '@/models/viewmodels/restaurant/RegisteredResultViewModel';
import {ModelStateErrors} from '@/models/application/ModelStateErrors';
import {
    getRegistrations,
    getRegistration,
    postRegistration,
    cancelRegistration
} from '@/api/restaurant';
import {to, reviveUTCDate} from '@/utils';
import {UserModule} from './user';
import {ApplicationModule} from './application';
import {AgenceModule} from './agence';

export interface IRestaurantState {
    registrations: Array<RegisteredResultViewModel>;
    todayRegistration: RegisteredResultViewModel | null;
    loadingRegistration: boolean;
    selectedRegistrationDate: string | null;
    registrationErrors: string[] | null;
    hasNetworkError: boolean;
}

@Module({dynamic: true, store, name: 'restaurant', namespaced: true})
class MRestaurant extends VuexModule implements IRestaurantState {
    public registrations: Array<RegisteredResultViewModel> = [];
    public todayRegistration: RegisteredResultViewModel | null = null;
    public loadingRegistration = false;
    public selectedRegistrationDate: string | null = null;
    public registrationErrors: string[] | null = null;
    public hasNetworkError = false;

    @Mutation
    private SET_REGISTRATIONS(registrations: Array<RegisteredResultViewModel>) {
        this.registrations = registrations;
    }

    @Mutation
    private SET_TODAY_REGISTRATION(registration: RegisteredResultViewModel | null) {
        this.todayRegistration = registration;
    }

    @Mutation
    private SET_LOADING(loading: boolean) {
        this.loadingRegistration = loading;
    }

    @Mutation
    private SET_SELECTED_REGISTRATION_DATE(date: string | null) {
        this.selectedRegistrationDate = date;
    }

    @Mutation
    private SET_ERRORS(errors: Array<string> | null) {
        this.registrationErrors = errors;
    }

    @Mutation
    private SET_HAS_NETWORK_ERRORS(value: boolean) {
        this.hasNetworkError = value;
    }

    @Action
    public async updateRegistrations(dateStr: string) {
        if (!UserModule.authenticated || !ApplicationModule.online) {
            this.SET_REGISTRATIONS([]);
            this.SET_HAS_NETWORK_ERRORS(true);
            return;
        }

        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            this.SET_REGISTRATIONS([]);
            this.SET_HAS_NETWORK_ERRORS(true);
            return;
        }

        ApplicationModule.setLoading(true);
        const [, data] = await to(getRegistrations(dateStr));
        ApplicationModule.setLoading(false);

        if (data) {
            this.SET_HAS_NETWORK_ERRORS(false);
            this.SET_REGISTRATIONS(
                data.map(el => {
                    return {
                        date: reviveUTCDate(el.date),
                        isRegistered: el.isRegistered,
                        canCancel: el.canCancel,
                        canRegister: el.canRegister,
                        maxCancelDate:
                            el.maxCancelDate !== undefined
                                ? reviveUTCDate(el.maxCancelDate)
                                : undefined,
                        maxRegisterDate:
                            el.maxRegisterDate !== undefined
                                ? reviveUTCDate(el.maxRegisterDate)
                                : undefined,
                        invites: el.invites,
                        isError: false
                    };
                })
            );
        } else {
            this.SET_REGISTRATIONS([]);
            this.SET_HAS_NETWORK_ERRORS(true);
        }
    }

    @Action
    public async getTodayRegistration() {
        this.SET_LOADING(true);
        const [, data] = await to(getRegistration(new Date().toISOString()));
        this.SET_LOADING(false);

        if (data) {
            this.SET_TODAY_REGISTRATION({
                date: reviveUTCDate(data.date),
                isRegistered: data.isRegistered,
                canCancel: data.canCancel,
                canRegister: data.canRegister,
                maxCancelDate:
                    data.maxCancelDate !== undefined
                        ? reviveUTCDate(data.maxCancelDate)
                        : undefined,
                maxRegisterDate:
                    data.maxRegisterDate !== undefined
                        ? reviveUTCDate(data.maxRegisterDate)
                        : undefined,
                invites: data.invites,
                isError: false
            });
        } else {
            this.SET_TODAY_REGISTRATION(null);
        }
    }

    @Action
    public async postNewRegistration(payload: {
        date: Date;
        comments: string;
        signature: string;
        invites: number;
        agenceId: string;
        isAllWeek?: boolean;
    }) {
        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            return;
        }

        ApplicationModule.setLoading(true);
        this.SET_ERRORS(null);
        const [, data] = await to(
            postRegistration({
                date: payload.date,
                isAllWeek: payload.isAllWeek,
                comments: payload.comments,
                invited: payload.invites,
                agence: payload.agenceId,
                signature: payload.signature
            })
        );
        ApplicationModule.setLoading(false);

        if (data) {
            if ((data as ModelStateErrors).errors) {
                // it means we have errors.
                const {errors} = data as ModelStateErrors;
                if (Array.isArray(errors) && errors.length > 0) {
                    this.SET_ERRORS(errors);
                }
                return;
            }
            if (!payload.isAllWeek && (data as RegisteredResultViewModel).date) {
                const result = data as RegisteredResultViewModel;
                result.date = reviveUTCDate(result.date);
                const inscriptions = cloneDeep(this.registrations);
                const pIndex = inscriptions.findIndex(
                    r =>
                        (r.date as Date).toISOString().substr(0, 10) ===
                        (result.date as Date).toISOString().substr(0, 10)
                );
                if (pIndex !== -1) {
                    inscriptions.splice(pIndex, 1);
                }
                inscriptions.push(result);
                this.SET_REGISTRATIONS(inscriptions);

                ApplicationModule.showSnackbar({
                    displayed: true,
                    text: 'Réservation confirmée.',
                    color: 'success',
                    timeout: 5 * 1000
                });
                return;
            }
            if (payload.isAllWeek && Array.isArray(data) && data.length > 0) {
                const results = data as Array<RegisteredResultViewModel>;
                const inscriptions = cloneDeep(this.registrations);
                results.forEach(registration => {
                    const revDate = reviveUTCDate(registration.date);
                    const pIndex = inscriptions.findIndex(
                        r =>
                            (r.date as Date).toISOString().substr(0, 10) ===
                            revDate.toISOString().substr(0, 10)
                    );
                    if (pIndex !== -1) {
                        inscriptions.splice(pIndex, 1);
                    }
                    inscriptions.push({
                        ...registration,
                        date: revDate
                    });
                });
                this.SET_REGISTRATIONS(inscriptions);
                ApplicationModule.showSnackbar({
                    displayed: true,
                    text: 'Réservations confirmées.',
                    color: 'success',
                    timeout: 5 * 1000
                });
                return;
            }
            ApplicationModule.showSnackbar({
                displayed: true,
                text: 'Aucune réservation effectuée',
                color: 'warning',
                timeout: 5 * 1000
            });
        }
    }

    @Action
    public async cancelRegistration(payload: {date: Date; comments: string; signature: string}) {
        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            return;
        }
        
        ApplicationModule.setLoading(true);
        this.SET_ERRORS(null);
        const [, data] = await to(
            cancelRegistration({
                date: payload.date,
                comments: payload.comments,
                invited: 0,
                agence: '',
                signature: payload.signature
            })
        );
        ApplicationModule.setLoading(false);

        if (data) {
            if ((data as ModelStateErrors).errors) {
                // it means we have errors.
                const {errors} = data as ModelStateErrors;
                if (Array.isArray(errors) && errors.length > 0) {
                    this.SET_ERRORS(errors);
                }
            } else if ((data as RegisteredResultViewModel).date) {
                const result = data as RegisteredResultViewModel;
                result.date = reviveUTCDate(result.date);
                const inscriptions = cloneDeep(this.registrations);
                const pIndex = inscriptions.findIndex(
                    r =>
                        (r.date as Date).toISOString().substr(0, 10) ===
                        (result.date as Date).toISOString().substr(0, 10)
                );
                if (pIndex !== -1) {
                    inscriptions.splice(pIndex, 1);
                }
                inscriptions.push(result);
                this.SET_REGISTRATIONS(inscriptions);

                ApplicationModule.showSnackbar({
                    displayed: true,
                    text: 'Réservation annulée.',
                    color: 'success',
                    timeout: 5 * 1000
                });
            }
        }
    }

    public get selectedRegistration() {
        if (!this.selectedRegistrationDate) {
            return null;
        }
        const pIndex = this.registrations.findIndex(
            r => (r.date as Date).toISOString().substr(0, 10) === this.selectedRegistrationDate
        );

        if (pIndex === -1) {
            return {
                date: new Date(`${this.selectedRegistrationDate}T12:00:00Z`),
                isError: true
            } as RegisteredResultViewModel;
        }

        return {...this.registrations[pIndex]};
    }
}

export const RestaurantModule = getModule(MRestaurant);
