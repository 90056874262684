




























































































































































































import {Vue, Component, Prop, PropSync} from 'vue-property-decorator';
import {ApplicationModule} from '@/store/modules/application';
import {RestaurantModule} from '@/store/modules/restaurant';

import VueSignature from '@/components/User/vue-signature.vue';
import NoConnection from '@/components/Home/NoConnection.vue';
import {padNumber, getFirstDayOfTheWeek, areSameDate} from '@/utils';
import {UserModule} from '@/store/modules/user';
import {AgenceModule} from '@/store/modules/agence';
import {isNumber} from 'lodash';

@Component({
    components: {
        VueSignature,
        NoConnection
    },
})
export default class extends Vue {
    @PropSync('showDialog') private syncedShowDialog!: boolean;
    @Prop({type: Boolean}) private isWeekRegistration!: boolean;
    @Prop({type: String}) private weekDate!: string;

    private comments = '';
    private invites = 0;
    public selectedAgenceId = '';

    public amountOptions = {
        numeral: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
        numeralPositiveOnly: true,
        delimiter: ' ',
        rawValueTrimPrefix: true,
    };

    private get online() {
        return ApplicationModule.online;
    }

    private get loading() {
        return ApplicationModule.loading;
    }

    public get hasNetworkError() {
        return RestaurantModule.hasNetworkError;
    }

    private get computedDateStr() {
        if (!this.selectedRegistration) {
            return '-';
        }
        const {date} = this.selectedRegistration;
        return this.$i18n.d(date as Date, 'weekdayStartFull');
    }

    public get agences() {
        const agencesIds = Object.keys(AgenceModule.agences);

        const agences = [{value: '', label: 'Aucune agence.'}];

        agencesIds.forEach((id) => {
            agences.push({value: id, label: AgenceModule.agences[id]});
        });

        return agences;
    }

    private get selectedRegistration() {
        return RestaurantModule.selectedRegistration;
    }

    private get displayAccessAlert() {
        return UserModule.syncedHasAccess !== true;
    }

    private get weekPeriod() {
        if (!this.isWeekRegistration) {
            return '-';
        }

        const date = getFirstDayOfTheWeek(new Date(`${this.weekDate}T12:00:00Z`));

        return `Semaine du ${this.$i18n.d(date, 'weekdayStart')} au ${this.$i18n.d(
            new Date(date.getTime() + 4 * 24 * 3600 * 1000),
            'weekdayEnd'
        )}`;
    }

    private get isLateRegister() {
        if (this.isWeekRegistration) {
            return false;
        }
        if (!this.selectedRegistration) {
            return false;
        }
        const {date} = this.selectedRegistration;
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return (
            areSameDate(date as Date, today) ||
            (areSameDate(date as Date, tomorrow) && today.getHours() >= 17)
        );
    }

    private checkSignature(): string | null {
        const signPad = this.$refs.signPad as unknown as VueSignature;
        if (signPad.isEmpty) {
            return null;
        }
        const nowDateTime = new Date();
        const dformat = `${[
            padNumber(nowDateTime.getDate()),
            padNumber(nowDateTime.getMonth() + 1),
            nowDateTime.getFullYear(),
        ].join('/')} à ${[
            padNumber(nowDateTime.getHours()),
            padNumber(nowDateTime.getMinutes()),
        ].join(':')}`;
        signPad.addWaterMark({
            text: `Signé le ${dformat}`,
            font: '15px Arial', // mark font, > default '20px sans-serif'
            style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
            fillStyle: 'red', // fillcolor, > default '#333'
            strokeStyle: 'blue', // strokecolor, > default '#333'
            x: 50, // fill positionX, > default 20
            y: 200, // fill positionY, > default 20
            sx: 50, // stroke positionX, > default 40
            sy: 200, // stroke positionY, > default 40
        });
        return signPad.save('image/jpeg');
    }

    private async createNewRegistration() {
        if (!this.selectedRegistration) {
            return;
        }

        const finalSign = this.checkSignature();
        if (!finalSign) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: 'Une inscription au restaurant ne peut-être envoyée sans être signée.',
                timeout: 5 * 1000,
            });
            return;
        }

        await RestaurantModule.postNewRegistration({
            date: this.selectedRegistration.date as Date,
            comments: this.comments,
            invites: +this.invites,
            agenceId: this.selectedAgenceId,
            signature: finalSign,
        });
        this.syncedShowDialog = false;
    }

    private async cancelRegistration() {
        if (!this.selectedRegistration || !this.selectedRegistration.isRegistered) {
            return;
        }
        if (!this.selectedRegistration.canCancel) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Il n'est pas possible d'annuler cette réservation.",
                timeout: 5 * 1000,
            });
            return;
        }

        const finalSign = this.checkSignature();
        if (!finalSign) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: 'Une annulation ne peut-être envoyée sans être signée.',
                timeout: 5 * 1000,
            });
            return;
        }

        await RestaurantModule.cancelRegistration({
            date: this.selectedRegistration.date as Date,
            comments: this.comments,
            signature: finalSign,
        });
        this.syncedShowDialog = false;
    }

    private async weekRegister() {
        if (!this.isWeekRegistration) {
            return;
        }

        const finalSign = this.checkSignature();
        if (!finalSign) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: 'Une inscription au restaurant ne peut-être envoyée sans être signée.',
                timeout: 5 * 1000,
            });
            return;
        }

        await RestaurantModule.postNewRegistration({
            date: getFirstDayOfTheWeek(new Date(`${this.weekDate}T12:00:00Z`)),
            isAllWeek: true,
            comments: this.comments,
            invites: 0,
            agenceId: '',
            signature: finalSign,
        });
        this.syncedShowDialog = false;
    }
}
