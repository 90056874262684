


































































import {Vue, Component} from 'vue-property-decorator';
import colors from 'vuetify/lib/util/colors';
import {addDays, areSameDate, reviveUTCDate} from '@/utils';
import {RestaurantModule} from '@/store/modules/restaurant';

import InscriptionModal from './InscriptionModal.vue';

@Component({
    components: {
        InscriptionModal,
    },
})
export default class extends Vue {
    private inToday = new Date();
    private inFocus: string | null = null;

    private get today() {
        return this.inToday.toISOString().substr(0, 10);
    }

    public get focus() {
        if (this.inFocus) {
            return this.inFocus;
        }
        const dow = this.inToday.getDay();
        if (dow === 0) {
            // exclude Sunday, go to Next monday
            return addDays(this.inToday, 1).toISOString().substr(0, 10);
        }
        if (dow === 6) {
            // exclude Saturday, go to Next monday
            return addDays(this.inToday, 2).toISOString().substr(0, 10);
        }
        return this.inToday.toISOString().substr(0, 10);
    }

    public set focus(value: string) {
        this.inFocus = value;
    }

    private bShowInscriptionModal = false;
    private bIsWeekRegistration = true;

    public getDayColor(dateStr: string) {
        if (this.bShowInscriptionModal) {
            return null;
        }
        const pIndex = RestaurantModule.registrations.findIndex((e) =>
            areSameDate(e.date as Date, reviveUTCDate(`${dateStr}T12:00:00Z`))
        );
        if (pIndex === -1 || RestaurantModule.registrations[pIndex]?.isError) {
            return '#ccc';
        }
        if (RestaurantModule.registrations[pIndex].isRegistered) {
            return colors.green.lighten1;
        }
        return RestaurantModule.registrations[pIndex].canRegister ? '#fff' : colors.red.lighten1;
    }

    public getInvites(dateStr: string) {
        if (this.bShowInscriptionModal) {
            return null;
        }
        const pIndex = RestaurantModule.registrations.findIndex((e) =>
            areSameDate(e.date as Date, reviveUTCDate(`${dateStr}T12:00:00Z`))
        );
        if (pIndex === -1) {
            return 0;
        }
        return RestaurantModule.registrations[pIndex].invites ?? 0;
    }

    public handleClickInterval(event: any) {
        this.bIsWeekRegistration = false;
        this.$store.commit('restaurant/SET_SELECTED_REGISTRATION_DATE', event.date);
        this.bShowInscriptionModal = true;
    }

    public updateRegistrations({start}: any) {
        this.refreshEvent(`${start.date}T00:00:00`);
    }

    public async refreshEvent(dateStr: string) {
        RestaurantModule.updateRegistrations(dateStr);
    }

    public handleLateActions() {
        this.$store.commit('restaurant/SET_SELECTED_REGISTRATION_DATE', this.today);
        this.bShowInscriptionModal = true;
        this.bIsWeekRegistration = false;
    }

    public async handleClickWeekRegister() {
        this.bShowInscriptionModal = true;
        this.bIsWeekRegistration = true;
    }
}
