







import {Vue, Component} from 'vue-property-decorator';
import Inscription from '@/components/Restaurant/Inscription.vue';
import Semaine from '@/components/Restaurant/Semaine.vue';

@Component({
    components: {
        Inscription, Semaine
    }
})
export default class extends Vue {

}
