



























    import {Vue, Component} from 'vue-property-decorator';
    import { ApplicationModule } from '@/store/modules/application';
    import { getRegistration } from '@/api/restaurant';
import { to, reviveUTCDate } from '@/utils';
import { RegisteredResultViewModel } from '@/models/viewmodels/restaurant/RegisteredResultViewModel';

    @Component
    export default class extends Vue {
        private registration: RegisteredResultViewModel | null = null;
        private loadingRegistration= false;

        private get online() {
            return ApplicationModule.online;
        }

        private get computedIsRegistered() {
            return this.registration?.isRegistered || false;
        }

        private get computedCanCancel() {
            if (!this.registration) {
                return false;
            }
            const { canCancel, maxCancelDate } = this.registration;
            return canCancel && ((maxCancelDate !== undefined) ? new Date().getTime() < (maxCancelDate as Date).getTime() : true);
        }

        private get computedCanRegister() {
            if (!this.registration) {
                return false;
            }
            const { canRegister, maxRegisterDate } = this.registration;
            return canRegister && ((maxRegisterDate !== undefined) ? new Date().getTime() < (maxRegisterDate as Date).getTime() : true);
        }

        private mounted() {
            this.$nextTick().then(async () => {
                this.loadingRegistration = true;
                const [,data] = await to(getRegistration(new Date().toISOString()))
                this.loadingRegistration = false;

                if (data) {
                    this.registration = {
                        date: reviveUTCDate(data.date),
                        isRegistered: data.isRegistered,
                        canCancel: data.canCancel,
                        canRegister: data.canRegister,
                        maxCancelDate: (data.maxCancelDate !== undefined) ? reviveUTCDate(data.maxCancelDate) : undefined,
                        maxRegisterDate: (data.maxRegisterDate !== undefined) ? reviveUTCDate(data.maxRegisterDate) : undefined,
                        invites: data.invites,
                        isError: false
                    }
                }
            });
        }

        private handleUnregister() {
            if (!this.computedCanCancel) {
                ApplicationModule.showSnackbar({
                    displayed: true,
                    text: "Vous ne pouvez pas annuler votre réservation d'aujourd'hui au restaurant.",
                    color: "error",
                    timeout: 5 * 1000
                })
            }

            this.$emit('lateaction');
        }

        private handleLateRegister() {
            if (!this.computedCanRegister) {
                ApplicationModule.showSnackbar({
                    displayed: true,
                    text: "Vous ne pouvez pas réserver votre place au restaurant aujourd'hui.",
                    color: "error",
                    timeout: 5 * 1000
                })
            }

            this.$emit('lateaction');
        }

    }
